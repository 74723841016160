import { GET, PUT } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryRestaurantPreference() {
  return GET(`${API_PREFIX}/v2/preference`);
}

export function updateRestaurantPreference(data) {
  return PUT(`${API_PREFIX}/v2/preference`, data);
}

import { getFieldValueByNamePath, setValueByNamePath } from 'src/utils/object';
import { queryRestaurantPreference, updateRestaurantPreference } from '../services/preference';
import TabletFieldPathConst from 'src/components/Settings/SmartOrdering/TabletFieldPathConst';
import { MENU_PAGE_ORITATION_ENUMS } from 'src/consts/menuDecorate';

const { menuDecorationLayoutNamePath } = TabletFieldPathConst;
const BASE = 'PREFERENCE';

export const DINE_IN_ONLINE_PAYMENT_TYPE = {
  online_payment_enable: 'online_payment_enable',
  online_payment_immediately: 'online_payment_immediately',
  online_payment_disable: 'online_payment_disable',
};

export const actions = {
  loadingPreference: `${BASE}_LOADING_PREFERENCE`,
  loadPreferenceSuccess: `${BASE}_LOAD_PREFERENCE_SUCCESS`,
  loadPreferenceFailed: `${BASE}_LOAD_PREFERENCE_FAILED`,

  updatingPreference: `${BASE}_UPDATING_PREFERENCE`,
  updatePreferenceSuccess: `${BASE}_UPDATE_PREFERENCE_SUCCESS`,
  updatePreferenceFailed: `${BASE}_UPDATE_PREFERENCE_FAILED`,
};

export const fetchRestaurantPreference = (refresh, callback) => async (dispatch, getState) => {
  const { isLoadPreferenceSuccess, preference: existedPreference } = getState().preference;

  if (!refresh && isLoadPreferenceSuccess) {
    typeof callback === 'function' && callback(existedPreference);
    return;
  }

  dispatch({
    type: actions.loadingPreference,
  });

  let response;

  try {
    response = await queryRestaurantPreference();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadPreferenceFailed,
    });
    return;
  }

  const preference = response.data || {};

  const dinein_online_payment_enable = preference.smart_ordering.dine_in_config.online_payment_enable;
  const dinein_online_payment_immediately = preference.smart_ordering.dine_in_config.online_payment_immediately;
  const tableService = preference.service_config?.table_service || false;
  let dinein_online_payment_type = DINE_IN_ONLINE_PAYMENT_TYPE.online_payment_disable;
  if (tableService) {
    if (dinein_online_payment_immediately) {
      dinein_online_payment_type = DINE_IN_ONLINE_PAYMENT_TYPE.online_payment_immediately;
    } else if (dinein_online_payment_enable) {
      dinein_online_payment_type = DINE_IN_ONLINE_PAYMENT_TYPE.online_payment_enable;
    }
  } else if (dinein_online_payment_enable) {
    dinein_online_payment_type = DINE_IN_ONLINE_PAYMENT_TYPE.online_payment_enable;
  }
  preference.smart_ordering = {
    ...(preference.smart_ordering || {}),
    dine_in_config: {
      ...preference.smart_ordering.dine_in_config,
      online_payment_type: dinein_online_payment_type,
    },
    web_page_config: {
      ...preference.smart_ordering.web_page_config,
      display_sold_out_dishes:
        preference.smart_ordering.web_page_config.display_sold_out_dishes === null
          ? true
          : preference.smart_ordering.web_page_config.display_sold_out_dishes,
      display_dish_foreign_names:
        preference.smart_ordering.web_page_config.display_dish_foreign_names === null
          ? true
          : preference.smart_ordering.web_page_config.display_dish_foreign_names,
    },
  };

  const layout = getFieldValueByNamePath(preference, menuDecorationLayoutNamePath);
  if (!layout) setValueByNamePath(preference, menuDecorationLayoutNamePath, MENU_PAGE_ORITATION_ENUMS.DEFAULT);

  dispatch({
    type: actions.loadPreferenceSuccess,
    payload: preference,
  });

  typeof callback === 'function' && callback(preference);
};

export const doUpdateRestaurantPreference = (data, callback) => async (dispatch) => {
  dispatch({
    type: actions.updatingPreference,
  });

  let response;

  try {
    response = await updateRestaurantPreference(data);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({
      type: actions.updatePreferenceFailed,
    });
    return;
  }

  dispatch({
    type: actions.updatePreferenceSuccess,
    payload: data,
  });
};

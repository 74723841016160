import { GET, PUT } from '../utils/request';
import { API_PREFIX } from '../consts';

export function queryEmployeeWorkflowSetting(restaurantId) {
  return GET(`${API_PREFIX}/v1/employee-workflow-setting`, { restaurant_id: restaurantId });
}

export function updateEmployeeWorkflowSetting(payload) {
  return PUT(`${API_PREFIX}/v1/employee-workflow-setting`, payload);
}

import { FONT_FAMILY } from './fonts';

export const MENU_PAGE_ORITATION_ENUMS = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
  DEFAULT: 'default',
};
export const ACTION_BUTTON_STYLE = {
  REGULAR: 'regular',
  TRANSPARENT: 'transparent',
};
export const MENU_PAGE_TOP_BAR_HEIGHT = 76;
export const MENU_TOOL_ZINDEX = 995;
export const HOTSPOT_LINE_HEIGHT = '1.2';
export const FLOAT_BUTTON_POSITION = {
  [MENU_PAGE_ORITATION_ENUMS.LANDSCAPE]: {
    x: 48,
    y: 48,
  },
  [MENU_PAGE_ORITATION_ENUMS.PORTRAIT]: {
    x: 48,
    y: 108,
  },
};
export const FLOAT_BUTTON_SIZE = 100;
export const FLOAT_BUTTON_RADIUS = 16;
export const TARGET_DISPLAY_SIZE = {
  WIDTH: 1920,
  HEIGHT: 1200,
};
export const THUMBNAIL_SCALE_RATIO = 4;
export const MENU_PAGE_THEME_CUSTOM = 'custom';
export const MENU_PAGE_THEME_WHITE = 'white';
export const MENU_PAGE_THEMES = {
  red: {
    background_color: '#C31F1F',
    name: 'Red',
    foreign_name: '红色',
  },
  orange: {
    background_color: '#E67A33',
    name: 'Orange',
    foreign_name: '橙色',
  },
  yellow: {
    background_color: '#F3BD36',
    name: 'Yellow',
    foreign_name: '黄色',
  },
  lemon: {
    background_color: '#E7EB27',
    name: 'Lemon',
    foreign_name: '柠檬黄',
  },
  mint: {
    background_color: '#79E1AD',
    name: 'Mint',
    foreign_name: '薄荷绿',
  },
  green: {
    background_color: '#478F4D',
    name: 'Green',
    foreign_name: '绿色',
  },
  baby_blue: {
    background_color: '#97E1EE',
    name: 'Baby Blue',
    foreign_name: '天蓝',
  },
  navy_blue: {
    background_color: '#194ED6',
    name: 'Navy Blue',
    foreign_name: '蓝色',
  },
  almond: {
    background_color: '#F9CDA0',
    name: 'Almond',
    foreign_name: '杏仁棕',
  },
  chestnut: {
    background_color: '#854B3C',
    name: 'Chestnut',
    foreign_name: '棕色',
  },
  pink: {
    background_color: '#FFA7B6',
    name: 'Pink',
    foreign_name: '浅粉色',
  },
  strawberry: {
    background_color: '#FC4C4E',
    name: 'Strawberry',
    foreign_name: '粉红色',
  },
  [MENU_PAGE_THEME_WHITE]: {
    background_color: '#FFFFFF',
    name: 'White',
    foreign_name: '白色',
  },
  black: {
    background_color: '#000000',
    name: 'Black',
    foreign_name: '黑色',
  },
  [MENU_PAGE_THEME_CUSTOM]: {
    background_color: '#DBDBDB',
    name: 'Custom',
    foreign_name: '自定义',
  },
};
export const TOP_BAR_KLASS = 'menu-page-toolbar';
export const TOP_BAR_HEIGHT = 76;
export const LAYOUT_PREFIX = {
  ROW: 'row',
  COLUMN: 'column',
  VERTICAL_ROW: 'vertical_row',
  VERTICAL_COLUMN: 'vertical_column',
};
export const HOTSPOT_LAYOUT = {
  //左右排列
  ROW_START: `${LAYOUT_PREFIX.ROW}_top`, //plus 按钮在右边顶部
  ROW_CENTER: `${LAYOUT_PREFIX.ROW}_center`, //plus 按钮在右边中间
  ROW_END: `${LAYOUT_PREFIX.ROW}_bottom`, //plus 按钮在右边底部
  //上下排列
  COLUMN_START: `${LAYOUT_PREFIX.COLUMN}_left`, //plus 按钮在底部左边
  COLUMN_CENTER: `${LAYOUT_PREFIX.COLUMN}_center`, //plus 按钮在右边中间
  COLUMN_END: `${LAYOUT_PREFIX.COLUMN}_right`, //plus 按钮在右边底部
  VERTICAL_ROW_START: `${LAYOUT_PREFIX.VERTICAL_ROW}_top`, //plus 按钮在右边顶部,左边文字垂直排列，一个文字一行
  VERTICAL_ROW_CENTER: `${LAYOUT_PREFIX.VERTICAL_ROW}_center`, //plus 按钮在右边中间,左边文字垂直排列，一个文字一行
  VERTICAL_ROW_END: `${LAYOUT_PREFIX.VERTICAL_ROW}_bottom`, //plus 按钮在右边底部,左边文字垂直排列，一个文字一行
  VERTICAL_COLUMN_START: `${LAYOUT_PREFIX.VERTICAL_COLUMN}_left`, //plus 按钮在底部左边,上面文字垂直排列，一个文字一行
  VERTICAL_COLUMN_CENTER: `${LAYOUT_PREFIX.VERTICAL_COLUMN}_center`, //plus 按钮在右边中间,上面文字垂直排列，一个文字一行
  VERTICAL_COLUMN_END: `${LAYOUT_PREFIX.VERTICAL_COLUMN}_right`, //plus 按钮在右边底部,上面文字垂直排列，一个文字一行
};
export const FONT_SIZE_LIMIT = {
  MIN: 20,
  MAX: 72,
};
export const ACTION_SIZE_LIMIT = {
  MIN: 40,
  MAX: 100,
};
const defaultFontSize = (FONT_SIZE_LIMIT.MIN + FONT_SIZE_LIMIT.MAX) / 2;
const defaultButtonSize = (ACTION_SIZE_LIMIT.MIN + ACTION_SIZE_LIMIT.MAX) / 2;
const DEFAULT_CN_FONT_FAMILY = FONT_FAMILY.HARMONYOS_SANS_SC;
const DEFAULT_EN_FONT_FAMILY = FONT_FAMILY.PROXIMA_NOVA;

export const HOTSPOT_DEFAULT_CONFIGS = {
  style: {
    enable_background_fill: false,
    background_color: '#000',
    enable_background_stroke: false,
    background_stroke_color: '#000',
  },
  layout: HOTSPOT_LAYOUT.ROW_START,
  dish_config: {
    dish_name: {
      display: true,
      font: {
        family: {
          en: DEFAULT_EN_FONT_FAMILY,
          zh: DEFAULT_CN_FONT_FAMILY,
        },
        color: '#000',
        size_mapping: {
          en: defaultFontSize,
          zh: defaultFontSize,
        },
      },
    },
    dish_detail: {
      display: true,
      font: {
        family: {
          en: DEFAULT_EN_FONT_FAMILY,
          zh: DEFAULT_CN_FONT_FAMILY,
        },
        color: '#000',
        size_mapping: {
          en: defaultFontSize,
          zh: defaultFontSize,
        },
      },
    },
    action_button: {
      display: true,
      style: ACTION_BUTTON_STYLE.REGULAR,
      background_color: '#000',
      content_color: '#fff',
      size: defaultButtonSize,
    },
    meal_instance_id: null,
  },
};
export const HOTSPOT_DISH_NAME_MIN_WIDTH = 250;
export const MENU_DECORATION_ERRORS = {
  NO_HOTSPOTS: 'no_hotspots',
  HOTSPOT_WITHOUT_MEAL: 'hotspot_without_meal',
  HOTSPOTS_OVERLAPS: 'hotspots_overlaps',
  NO_BACKGROUND_IMAGE: 'no_background_image',
};
export const ERROR_TYPES_TO_DETECT = [
  MENU_DECORATION_ERRORS.NO_BACKGROUND_IMAGE,
  MENU_DECORATION_ERRORS.HOTSPOT_WITHOUT_MEAL,
  MENU_DECORATION_ERRORS.HOTSPOTS_OVERLAPS,
];
export const ALIGEMENT_LINE_DIRECTION = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};
